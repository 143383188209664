import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import '../styles/SignUp.css';

function Profile() {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [user_type, setUserType] = useState("");
    const [school, setSchool] = useState("");
    const [email, setEmail] = useState("");
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        // Fetch the current user details
        const fetchCurrentUser = async () => {
          try {
            const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, {
              headers: {
                'Authorization': `Token ${token}`
              }
            });
            if (response.data.user_type == 2) {
              setUserType('Administrator');
            } else {
              setUserType('Student');
            }
            setSchool(response.data.school.name);
            setEmail(response.data.email);
            setIsLoading(false);
          } catch (error) {
            console.error("Error fetching current user:", error);
            setIsLoading(false);
          }
        };
    
        fetchCurrentUser();
      }, [setUserType]);

    const handleSubmit = async e => {
        localStorage.removeItem('token');        
        navigate('/')
        window.location.reload();
    };

    return (
        <div className='page-container'>
        <Container className="signup-container">
            <h2 className="center-text">My Profile</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label className="bold-label">User Type</Form.Label>
                    <Form.Control e="text" name="User Type" value={user_type}  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label className="bold-label">School</Form.Label>
                    <Form.Control type="text" name="School" value={school} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label className="bold-label">Email</Form.Label>
                    <Form.Control type="email" name="email" value={email}  />
                </Form.Group>
                 <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Sign out
                    </Button>
                </div>
            </Form>
        </Container>
        </div>
    );
}

export default Profile;
