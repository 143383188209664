import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const CourseCard = ({ course }) => {
  const navigate = useNavigate();
  const [schoolUnits, setSchoolUnits] = useState([]);
  const [isUserCourse, setIsUserCourse] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token');

  const styles = {
    modeTag: {
      backgroundColor: '#e0e0e0', // Light grey background, adjust as needed
      color: '#333', // Dark text color for contrast, adjust as needed
      padding: '2px 8px',
      borderRadius: '4px',
      display: 'inline-block', // Ensure it's treated as an inline element for layout
      fontSize: '0.8em', // Smaller font size for tag-like appearance
    },


    strong: {
      display: 'block',
      margin: '5px 0',
    },
    card: {
      marginTop: '20px',
      width: '100%',
      padding: '20px',
      borderLeft: '8px solid var(--moonless-night-black)',
      borderRadius: '0 10px 10px 0',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      cursor: 'pointer',
    },
    content: {
      marginLeft: '10px',
    },
    h3: {
      marginTop: '0',
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A',
      textDecoration: 'none',
    },
    requirementsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    requirement: {
      padding: '0px 10px',
      borderRadius: '5px',
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      fontSize: '24px',
      alignSelf: 'flex-start',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    schoolAndModeContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px', // Adjust the space between the school name and the mode tag as needed
    },
    checkmark: {
      color: '#4CAF50',
      marginLeft: '10px',
    },

  };

  useEffect(() => {
    const headers = {};
        if (token) {
          headers['Authorization'] = `Token ${token}`;
        }
    const fetchSchoolUnits = async () => {
      try {
        // Replace 'API_ENDPOINT' with your actual API endpoint
        const response = await axios.get(`${API_ENDPOINT}/api/course-units/${course.uuid}`, { headers });
        setSchoolUnits(response.data);
      } catch (error) {
        console.error('Error fetching school units:', error);
      }
    };

    const checkUserCourse = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/check-course-scheduled/${course.uuid}`, { headers });
        setIsUserCourse(response.data.isScheduled);
      } catch (error) {
        console.error('Error checking if course is in user courses:', error);
      }
    };

    if (course.uuid) {
      fetchSchoolUnits();
      checkUserCourse();
    }
  }, [course.uuid]);

  const handleCardClick = () => {
    navigate(`/course-page/${course.uuid}`);
  };

  // Function to process and group non-duplicable requirements while avoiding duplicates
  const processRequirements = () => {
    let displayReqs = [];
  
    // Updated structure to hold both names and colors
    const uniqueReqs = new Map();
  
    // Handle non-duplicable groups
    course.non_duplicables.forEach((nonDupGroup) => {
      const groupNames = nonDupGroup.requirements.map(req => req.name).join(' OR ');
      const groupColors = nonDupGroup.requirements.map(req => req.pathway_colors ? req.pathway_colors[0] : 'defaultColor');
      // Assuming the same color for all requirements in a group, or picking the first one's color
      const color = groupColors[0]; // This can be adjusted based on how you want to handle colors for grouped requirements
      // Add to the uniqueReqs map to ensure no duplicates, storing both name and color
      nonDupGroup.requirements.forEach(req => uniqueReqs.set(req.uuid, { name: groupNames, color: color, pathway: req.pathway_names[0] }));
    });
  
    // Add individual requirements if they are not part of any non-duplicable group
    course.requirements.forEach(req => {
      if (!uniqueReqs.has(req.uuid)) {
        const color = req.pathway_colors && req.pathway_colors[0] ? req.pathway_colors[0] : 'defaultColor';
        const pathwayName = req.pathway_names[0];
        uniqueReqs.set(req.uuid, { name: req.name, color: color, pathway: pathwayName });
      }
    });
  
    // Convert the unique requirements map to an array for rendering, preserving both name and color
    uniqueReqs.forEach((value, key) => {
      if (!displayReqs.find(req => req.name === value.name)) { // Ensure no duplicate groups are added
        displayReqs.push(value);
      }
    });
  
    return displayReqs;
  };
  
  console.log(course)

  return (
    <div onClick={handleCardClick} style={styles.card} >
      <div style={styles.content}>
        <h3 style={styles.h3}>
            {course.course_id + ": " + course.title}
            {isUserCourse && <FontAwesomeIcon icon={faCheckCircle} style={styles.checkmark} />}
          </h3>
        <div style={styles.schoolAndModeContainer}>
          <strong style={styles.strong}>{course.school.name}</strong>        
          {course.mode && course.mode.trim() !== '' && (
            <span style={styles.modeTag}>{course.mode}</span>
          )}
        </div>        
        <p>{course.description}</p>

        {/* Conditional rendering for Prerequisites */}
        {course.prerequisites && course.prerequisites.length > 0 && (
          <>
            <strong style={styles.strong}>Prerequisites</strong>
            <div style={styles.requirementsContainer}>
              {course.prerequisites.map((prereq, index) => {
                return (
                  <div 
                    key={index} 
                    style={{
                      ...styles.requirement, 
                      backgroundColor: '#FFE27B' // Adjust this color as needed
                    }}
                  >
                    {`${prereq.course_id}: ${prereq.title}`}
                  </div>
                );
              })}
            </div>
          </>
        )}
        
         {/* Rendering units for each school */}
         {schoolUnits && (
          <>
            <strong style={styles.strong}>Transferability</strong>
            <div style={styles.requirementsContainer}>
              {schoolUnits.map((su, index) => (
                <div 
                  key={index} 
                  style={{
                    ...styles.requirement, 
                    backgroundColor: '#FFE27B' // Adjust this color as needed
                  }}
                >
                  {`${su.school_name}: ${su.units} units`}
                </div>
              ))}
            </div>
          </>
        )}



        {/* Conditional rendering for Requirements */}
        {course.requirements && course.requirements.length > 0 && (
          <>
            <strong style={styles.strong}>Requirements Fulfilled</strong>
            <div style={styles.requirementsContainer}>
              {processRequirements().map((req, index) => {
                return (
                  <div 
                    key={index} 
                    style={{
                      ...styles.requirement, 
                      backgroundColor: '#FFE27B'
                    }}
                  >
                    {`${req.pathway}: ${req.name}`}
                  </div>
                );
              })}
            </div>
          </>
        )}

       
  
        
      </div>
    </div>
  );
  };

export default CourseCard;
