import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import '../styles/SignUp.css';

function SignUp() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  

  const fetchCurrentUserAndNavigate = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const headers = {
          'Authorization': `Token ${token}`,
        };
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
        if (response.data.user_type === 2) { // Assuming 2 is admin
          navigate('/courses');
        } else {
          navigate('/plan');
        }
      }
    } catch (error) {
      console.error("Error fetching current user:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/login/`, {
        email,
        password,
      });
      localStorage.setItem('token', response.data.token);
      await fetchCurrentUserAndNavigate();
      window.location.reload();
    } catch (error) {
      console.error('Error logging in', error);
      alert('Login failed! Please check your credentials and try again.'); // Failure alert
    }
  };

  return (
    <div className="signup-page">
    <Container className="signup-container">
      <h2 className="center-text">Sign In</h2>
      <p className="center-text">Not yet registered? <Link to="/sign-up">Sign Up</Link></p>
      <p className="center-text">
        <Link to="/forgot-password">Forgot your password?</Link>
      </p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label className="bold-label">Email</Form.Label>
          <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label className="bold-label">Password</Form.Label>
          <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>
        <div className="center-text">
          <Button variant="primary" type="submit" className="btn-block custom-button">
            Sign In
          </Button>
        </div>
      </Form>
    </Container>
    </div>
  );
}

export default SignUp;
