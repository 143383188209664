import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const SortableCourse = ({ id, course }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // State to store the scheduled course details
  const [completed, setCompleted] = useState(false);
  const [grade, setGrade] = useState('');

  useEffect(() => {
    const fetchScheduledCourse = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/get-scheduled-course/${course.uuid}/`, {
          headers: { Authorization: `Token ${localStorage.getItem('token')}` },
        });
        const scheduledCourse = response.data;

        // Update the component state with the completed status and grade
        setCompleted(scheduledCourse.completed);
        setGrade(scheduledCourse.grade || '');
      } catch (error) {
        console.error('Error fetching scheduled course:', error);
      }
    };

    fetchScheduledCourse();
  }, [course.uuid, API_ENDPOINT]);

  const handleClick = () => {
    if (!isDragging) {
      navigate(`/course-page/${course.uuid}`);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: '10px',
    margin: '5px',
    backgroundColor: isDragging ? '#EAEAEA' : 'white',
    border: completed ? '4px solid grey' : '4px solid #FFE27B', // Grey border if completed
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    cursor: isDragging ? 'grabbing' : 'pointer',
    width: '150px',
    opacity: completed ? 0.6 : 1, // Slightly fade completed courses
  };

  const iconStyle = {
    marginRight: '3px',
    cursor: completed ? 'default' : 'grab', // Remove grab cursor if completed
    display: completed ? 'none' : 'block', // Hide drag icon if completed
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div {...listeners} style={iconStyle}>
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
      <div onClick={handleClick} style={{ flexGrow: 1 }}>
        {course.course_id}
      </div>
    </div>
  );
};

export default SortableCourse;
