import React, { useState, useEffect } from 'react';
import Callout from '../Callout'; // Assuming you've already created this component
import Button from '../Button'; // Assuming you have a reusable Button component
import PathwayCard from './PathwayCard';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';
import loadingGif from '../../assets/images/animation.gif';

import { Form, Container } from 'react-bootstrap';


const PathwaysTab = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [pathways, setPathways] = useState([]);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage

  const [pathwayName, setPathwayName] = useState('');
  const [pathwayDescription, setPathwayDescription] = useState('');
  const [pathwayColor, setPathwayColor] = useState('');


  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const headers = {
      Authorization: `Token ${token}`,
    };

    const pathwayData = {
      name: pathwayName,
      description: pathwayDescription,
      color: pathwayColor,
    };

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/pathways/create/`, pathwayData, { headers });

      // Assuming response.data contains the new pathway object
      setPathways(prevPathways => [...prevPathways, response.data]);
  
      // Reset form fields
      setPathwayName('');
      setPathwayDescription('');
      setPathwayColor('');
  
      // Close the overlay
      setIsOverlayVisible(false);
      } catch (error) {
      console.error('Error creating pathway:', error);
    }
  };



  useEffect(() => {
    // Fetch pathways from the backend
    const fetchPathways = async () => {
      try {
        const headers = {
          Authorization: `Token ${token}`, // Include the token in the request headers
        };

        const response = await axios.get(`${API_ENDPOINT}/api/pathways/`, { headers });
        setPathways(response.data); // Update the pathways state
        setIsLoading(false); 
      } catch (error) {
        console.error('Error fetching pathways:', error);
      }
    };

    fetchPathways();
  }, []); // Dependency on token ensures this runs when the token changes

  const toggleOverlay = () => {
      setIsOverlayVisible(!isOverlayVisible);
  };

  const pathwayForm = (
    <form onSubmit={handleFormSubmit}>
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayName" className="form-label">Pathway Name</label>
            <input type="text" className="form-control" id="pathwayName" name="pathwayName" value={pathwayName} onChange={(e) => setPathwayName(e.target.value)}/>
        </div>

        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayDescription" className="form-label">Pathway Description</label>
            <textarea className="form-control" id="pathwayDescription" name="pathwayDescription" rows="3" value={pathwayDescription} onChange={(e) => setPathwayDescription(e.target.value)}></textarea>
        </div>

        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayColor" className="form-label" >Pathway Color</label>
            <select className="form-select" id="pathwayColor" name="pathwayColor" value={pathwayColor} onChange={(e) => setPathwayColor(e.target.value)}>
            <option value="null"></option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
                <option value="purple">Purple</option>
                <option value="orange">Orange</option>
                <option value="pink">Pink</option>
            </select>
        </div>

        <Button type="submit" className="btn btn-primary">Save Pathway</Button>
    </form>
);

  

  return (
    <div className="page-container">
    <h1>Pathways</h1>
    <div>
      <Button onClick={toggleOverlay}>Add Pathway</Button>
      <Callout text="Pathways are any set of requirements to achieve an academic goal. You can create and manage your own pathways or view pathways offered by partnering community colleges. This will help you ensure you are offering all the courses required for students to achieve their academic goals." />      
      {isOverlayVisible && (
        <InputOverlay
            title="Create New Pathway"
            description="Please enter the details for the new pathway below."
            onClose={toggleOverlay}
            content={pathwayForm}
        />
      )}
      {isLoading ? (
<div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
        </div>
    ) : (
      <div style={{ marginTop: '20px' }}>
        {/* Placeholder for pathway cards */}
        <div>
        {pathways.map(pathway => (          
          <PathwayCard key={pathway.uuid} pathway={pathway} />
        ))}
      </div>
      </div>
    )}
    </div>
    </div>
  );
}

export default PathwaysTab;
